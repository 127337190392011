import React, { useState } from "react"
import { Link } from "gatsby"

const Header = props => {
  const [isActive, setIsActive] = useState(false)

  function getContrast(hexcolor) {
    // If a leading # is provided, remove it
    if (hexcolor.slice(0, 1) === "#") {
      hexcolor = hexcolor.slice(1)
    }

    // If a three-character hexcode, make six-character
    if (hexcolor.length === 3) {
      hexcolor = hexcolor
        .split("")
        .map(function (hex) {
          return hex + hex
        })
        .join("")
    }

    // Convert to RGB value
    let r = parseInt(hexcolor.substr(0, 2), 16)
    let g = parseInt(hexcolor.substr(2, 2), 16)
    let b = parseInt(hexcolor.substr(4, 2), 16)

    // Get YIQ ratio
    let yiq = (r * 299 + g * 587 + b * 114) / 1000

    // Check contrast
    return yiq >= 128 ? "dark" : "light"
  }

  return (
    <div
      className={`Navbar absolute top-0 left-0 w-full header-${getContrast(
        props.bgColor || "#ffffff"
      )}`}
    >
      <nav className="">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Link to="/" className="text-xl font-bold">
                  CrushToday
                </Link>

                {/* <img className="h-8 w-8" src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg" alt="Workflow" /> */}
              </div>
            </div>
            {/* <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
                <div className="ml-10 flex items-baseline space-x-4">
                  <Link to="/work" className="px-3 py-2 text-sm font-medium">
                    Projects
                  </Link>

                  <Link to="/about" className="px-3 py-2 text-sm font-medium">
                    About
                  </Link>

                  <Link to="/blog" className="px-3 py-2 text-sm font-medium">
                    Blog
                  </Link>

                  <Link to="/contact" className="px-3 py-2 text-sm font-medium">
                    Contact
                  </Link>
                </div>
              </div>
            </div> */}
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={e => setIsActive(!isActive)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 focus:outline-none "
              >
                <span className="sr-only">Open main menu</span>

                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>

                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Header
