import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Container from "../components/container"
import Layout from "../components/layout"

const blogPosts = ({ data, pageContext }) => {
  const { frontmatter, body } = data.mdx
  const { previous, next } = pageContext
  console.log(frontmatter)
  return (
    <Layout bgColor={frontmatter.featureColor}>
      {frontmatter.defaultTheme ? (
        <>
          <div
            className="hero py-48 md:py-52"
            style={{ backgroundColor: `${frontmatter.featureColor}` }}
          >
            <Container>
              <h1 className="text-white text-4xl md:text-5xl font-bold text-center mb-16">
                {frontmatter.title}
              </h1>
              {frontmatter.featureContent}
            </Container>
          </div>
          <Container>
            <article className="prose pt-10">
              <p>{frontmatter.date}</p>
              <MDXRenderer>{body}</MDXRenderer>
            </article>
          </Container>
        </>
      ) : (
        <MDXRenderer>{body}</MDXRenderer>
      )}
      <Container>
        {previous === false ? null : (
          <>
            {previous && (
              <Link to={`/blog${previous.fields.slug}`}>
                <button>{previous.frontmatter.title}</button>
              </Link>
            )}
          </>
        )}
        {next === false ? null : (
          <>
            {next && (
              <Link to={`/blog${next.fields.slug}`}>
                <button>{next.frontmatter.title}</button>
              </Link>
            )}
          </>
        )}
      </Container>
    </Layout>
  )
}

export default blogPosts

export const query = graphql`
  query PostsBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date(formatString: "Do MMMM YYYY")
        featureColor
        featureContent
        defaultTheme
      }
    }
  }
`
