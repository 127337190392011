import React from "react"
import { motion } from "framer-motion"
import Header from "../components/header"

const layout = props => {
  return (
    <motion.div exit={{ opacity: 0 }}>
      <Header bgColor={props.bgColor} />
      {props.children}
    </motion.div>
  )
}

export default layout
